import { FieldDefinitionsDto, IdeaAttributeDto, IdeaDto, IdeaFieldNames, IdeaStatus, Sort } from "api-shared";
import { memoize } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, SortingRule } from "react-table";
import BaseTable, { IBaseTableProps } from "../../components/table/BaseTable";
import TableHeaderCell from "../../components/table/TableHeaderCell";
import { useDefaultCurrency } from "../../domain/currencies";
import { useAllUsers } from "../../domain/users";
import { IDEA_FIELD_PREFIX } from "../../translations/field-translations";
import { translationKeys } from "../../translations/main-translations";
import { useIdeaTableCellRenderers } from "./hooks";

const getWidthForColumn = (columnName: string) => {
    let defaultWidth = 150;
    if (columnName === IdeaFieldNames.DisplayId) {
        defaultWidth = 80;
    }
    return defaultWidth;
};

export interface IIdeaTableProps extends Omit<IBaseTableProps<IdeaDto>, "columns" | "noDataText" | "translate" | "onSortByChanged"> {
    attributes: IdeaAttributeDto[];
    fieldDefinitions: FieldDefinitionsDto;
    columns: string[];
    sort: Sort;
    sortBy: string;
    onSortByChanged?: (newSortBy: SortingRule<IdeaDto>) => void;
}

export const IdeaTable = ({ attributes, columns, fieldDefinitions, sortBy, sort, onSortByChanged, ...tableProps }: IIdeaTableProps) => {
    const { t } = useTranslation();
    const clientCurrency = useDefaultCurrency();

    const users = useAllUsers();
    const resolveCellRenderer = useIdeaTableCellRenderers({
        fieldDefinitions,
        users,
        attributes,
    });

    const getColumnAccessor = useMemo(() => {
        return memoize((columnName: string) => {
            const field = fieldDefinitions[columnName];
            if (field === undefined) {
                return () => "";
            }
            if (field.name === IdeaFieldNames.ReactionsCount) {
                return (row: IdeaDto) => row.reactions.length;
            }
            if (field?.attributeName != null) {
                return (row: IdeaDto) => row.fields[columnName];
            }
            // Although FieldDefinition.name is a string, we can be safe here to assume that only keys of IdeaDto are left
            return (row: IdeaDto) => row[field.name as keyof IdeaDto];
        });
    }, [fieldDefinitions]);

    const tableColumns = useMemo<Column<IdeaDto>[]>(
        () =>
            columns.map((columnName) => {
                return {
                    Header: TableHeaderCell,
                    id: columnName,
                    label: t(
                        Object.values(IdeaFieldNames).includes(columnName as IdeaFieldNames)
                            ? `${IDEA_FIELD_PREFIX}.${columnName}`
                            : columnName,
                        { clientCurrency: clientCurrency.isoCode },
                    ),
                    accessor: getColumnAccessor(columnName),
                    disableSortBy: !fieldDefinitions[columnName]?.isSortable,
                    Cell: resolveCellRenderer(columnName),
                    width: getWidthForColumn(columnName),
                };
            }),
        [columns, fieldDefinitions, getColumnAccessor, t, clientCurrency, resolveCellRenderer],
    );

    const onSortByChangedCallback = useCallback(
        (newSortBy: SortingRule<IdeaDto>[]) => {
            if (onSortByChanged != null && newSortBy.length > 0) {
                onSortByChanged(newSortBy[0]);
            }
        },
        [onSortByChanged],
    );

    const defaultSortBy = useMemo<SortingRule<IdeaDto>[]>(() => [{ id: sortBy, desc: sort === Sort.DESCENDING }], [sortBy, sort]);

    const isIdeaDiscarded = useCallback((row: IdeaDto): boolean => row.status === IdeaStatus.DISCARDED, []);

    return (
        <BaseTable<IdeaDto>
            key={JSON.stringify(defaultSortBy)}
            defaultSortBy={defaultSortBy}
            onSortByChanged={onSortByChangedCallback}
            columns={tableColumns}
            fullHeight
            noDataText={t(translationKeys.VDLANG_IDEAS_NO_IDEAS_YET)}
            translate={t}
            rowHover
            isRowSelected={isIdeaDiscarded}
            {...tableProps}
        />
    );
};
