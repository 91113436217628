import { formControlClasses, Popover, styled } from "@mui/material";
import { AclNamespaces, AclPermissions } from "api-shared";
import { useTranslation } from "react-i18next";
import { MultiValue, OnChangeValue } from "react-select";
import SelectStaticMenuContainer from "../../components/input/select/components/SelectStaticMenu";
import Select from "../../components/input/select/Select";
import SelectButton from "../../components/input/select/SelectButton";
import { ISelectProps, Option, ValueHandler } from "../../components/input/select/types";
import { useUserHasPermissionQuery, useUsersHavingAccessPermissionQuery } from "../../domain/permissions";
import { useAllUsers, useCurrentUserId } from "../../domain/users";
import useMenu from "../../hooks/useMenu";
import { formatUser } from "../../lib/formatters";

interface IIdeaUserSelectFilterProps<IsMulti extends boolean = true>
    extends Omit<ISelectProps<Option<number>, IsMulti>, "options" | "value" | "onChange"> {
    value: number[];
    label: string;
    onChange: ValueHandler<number, IsMulti>;
    size?: "small" | "medium";
    disabled?: boolean;
    fullWidth?: boolean;
}

const SelectContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(33),
    [`& .${formControlClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const IdeaUserSelectFilter = ({
    value,
    label,
    onChange,
    className,
    size = "medium",
    disabled = false,
    fullWidth,
}: IIdeaUserSelectFilterProps) => {
    const { openMenu, menuProps } = useMenu();
    const { t } = useTranslation();
    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });
    const havingIdeaAccessPermissionQuery = useUsersHavingAccessPermissionQuery(AclNamespaces.Idea);
    const allUsers = useAllUsers();
    const currentUserId = useCurrentUserId();

    if (!havingIdeaAccessPermissionQuery.isSuccess || !hasPersonFilterPermissionQuery.isSuccess) {
        return null;
    }

    const allAllowedUsers = allUsers.filter((user) => havingIdeaAccessPermissionQuery.data.combinedUserIds.includes(user.id));
    const options = allAllowedUsers.map((user) => ({
        value: user.id,
        label: formatUser(user, { translate: t }),
    }));

    const currentUserOption = options.filter((option) => option.value === currentUserId);
    const availableOptions = hasPersonFilterPermissionQuery.data.hasPermission ? options : currentUserOption;

    const selectedOptions = options.filter((option) => value.includes(option.value));

    const updateValue = (newValue: MultiValue<Option<number>>) => {
        onChange(newValue.map((v) => v.value));
    };

    const handleChange = (value: OnChangeValue<Option, true>) => {
        updateValue(value);
    };

    return (
        <>
            <Popover {...menuProps}>
                <SelectContainer>
                    <Select
                        isClearable
                        isSearchable
                        menuIsOpen={!disabled}
                        isMulti
                        smallGutters
                        size="small"
                        margin="none"
                        value={selectedOptions}
                        options={availableOptions}
                        onChange={handleChange}
                        placeholder={`${t("Search")}...`}
                        isDisabled={disabled}
                        components={{
                            Menu: SelectStaticMenuContainer,
                            DropdownIndicator: undefined,
                        }}
                    />
                </SelectContainer>
            </Popover>
            <SelectButton
                label={label}
                count={selectedOptions.length}
                className={className}
                onClick={openMenu}
                aria-expanded={menuProps.open ? "true" : "false"}
                size={size}
                disabled={disabled && value.length === 0}
                fullWidth={fullWidth}
            />
        </>
    );
};

export default IdeaUserSelectFilter;
